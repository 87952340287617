/**
 * @author Akshay Kumar Singh
 * @file QuantifiableModal
 * @flow
 */
import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import * as CONSTANT from '../../shared/constants/data';
import { getData } from '../utils/storage.util';
import AlertModal from './AlertModal';
import LANGUAGECONST from '../constants/language';
import AuditWriteBack from '../../components/AuditDetailsComponent/AuditWriteBack';

/**
 * This functional component opens the confirmational modal.
 */
class QuantifiableModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInspected: 0,
            isApplicable: 0,
            isRepeatedFinding: 0,
            isNotAccessed: null,
            isAuditEdit: false,
            applicableData: []
        };
    }

    componentDidMount() {
        let { isApplicable, isInspected, isRepeatedFinding } = this.state;
        if (!!this.props.quesData.isApplicable) {
            this.setState(
                {
                    isApplicable: isApplicable == 0 ? 1 : 0
                },
                () => {
                    let element = document.getElementById('btn-1').classList;
                    element.toggle('active');
                }
            );
        }
        if (!!this.props.quesData.isRepeatedFinding) {
            this.setState(
                {
                    isRepeatedFinding: isRepeatedFinding == 0 ? 1 : 0
                },
                () => {
                    let element = document.getElementById('btn-2').classList;
                    element.toggle('active');
                }
            );
        }
        // if (!!this.props.quesData.isInspected) {
        //   this.setState({
        //     isInspected: (isInspected == 0) ? 1 : 0
        //   }, () => {
        //     let element = document.getElementById("btn-2").classList;
        //     element.toggle('active');
        //   });
        // }
    }

    onSave(validation) {
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { isInspected, isApplicable, isRepeatedFinding } = this.state;
        let { selectedType, quesData, taskInfo } = this.props;
        let { task_status } = taskInfo;
        if (
            !!validation ||
            !!isInspected ||
            !!isApplicable ||
            (selectedType === 3 && task_status == 1) ||
            (selectedType === 3 &&
                quesData.long_heading != 'General Utilities' &&
                quesData.long_heading != 'PARTS ROOM SAFETY & SECURITY')
        ) {
            this.props.onClose();
        } else {
            AlertModal.showAlert(lang.STRINGS.AllFieldsRequired);
        }
    }

    setIsAuditEdit = () => {
        this.setState({
            isAuditEdit: true
        });
    };

    toggleBtn = (id, index) => {
        let { taskInfo } = this.props;
        let { task_status } = taskInfo;
        let { isApplicable, isInspected } = this.state;
        if (task_status != 3) {
            if (id === 'btn-1') {
                this.setState(
                    {
                        isApplicable: isApplicable == 0 ? 1 : 0
                    },
                    () => {
                        this.setIsApplicable(id, index);
                    }
                );
            } else {
                this.setState(
                    {
                        isInspected: isInspected == 0 ? 1 : 0
                    },
                    () => {
                        this.setIsInspected(id, index);
                    }
                );
            }
        }
    };

    toggleBtnRepeatedFinding = (id, index) => {
        const { taskInfo } = this.props;
        const { task_status } = taskInfo;
        const { isRepeatedFinding } = this.state;

        if (task_status != 3) {
            this.setState({ isRepeatedFinding: isRepeatedFinding == 0 ? 1 : 0 }, () => {
                this.setIsRepeatedFinding(id, index);
            });
        } else {
            this.setState({ isRepeatedFinding: isRepeatedFinding == 0 ? 1 : 0 }, () => {
                this.setIsRepeatedFinding(id, index);
            });
        }
    };

    setIsInspected = (id, index) => {
        let { isInspected } = this.state;
        let element = document.getElementById(id).classList;
        element.toggle('active');
        this.props.isInspected(index, isInspected);
    };

    setIsApplicable = (id, index) => {
        let { isApplicable } = this.state;
        let element = document.getElementById(id).classList;
        element.toggle('active');
        this.props.isApplicable(index, isApplicable);
    };
    setIsRepeatedFinding = (id, index) => {
        let { isRepeatedFinding } = this.state;
        let element = document.getElementById(id).classList;
        element.toggle('active');
        this.props.isRepeatedFinding(index, isRepeatedFinding);
    };

    setApplicableData = (data) => {
        this.setState({
            applicableData: data
        });
    };

    render() {
        let {
            index,
            quesData,
            isQuantifiableModalOpen,
            PMQualityOptions,
            DVOptions,
            selectedType,
            taskInfo
        } = this.props;

        let { task_status } = taskInfo ? taskInfo : {};
        let { userData } = getData('loginData');
        let isDisabled =
            (userData.role_name === 'auditor' && task_status == 2) || task_status == 3
                ? true
                : false;
        let lang = LANGUAGECONST[this.props?.langData?.langType || 'ENG'];
        let { isApplicable, isAuditEdit, applicableData, isRepeatedFinding } = this.state;

        return (
            <Modal
                open={isQuantifiableModalOpen}
                onClose={this.props.onClose}
                closeOnEsc={false}
                closeOnOverlayClick={false}
                center>
                <div className="pop-up-bg" id="create-audit-popup">
                    <div className="rqst-accs-popup">
                        <div className="create-audit-outer">
                            {/* <div className="top-cntnt">
                <a href="!#" onClick={(evt) => { onCancel(evt) }}>
                  <img src={require("../../assets/images/cross-icon.png")} alt="cross-icon" />
                </a>
              </div> */}
                        </div>
                        <div className="toggle-block">
                            <div className="toggle-row">
                                <div className="toggle-block-1">
                                    <span className="toggle-lable">
                                        {lang.LABLES.NotApplicable}
                                    </span>
                                    <div className="toggle-btn-container-1">
                                        <div
                                            className="toggle-btn"
                                            id="btn-1"
                                            onClick={() => this.toggleBtn('btn-1', index)}>
                                            <div className="inner-circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {quesData.long_heading !== 'Diagnostic Visit' && (
                                <div className="toggle-row">
                                    <div className="toggle-block-1">
                                        <span className="toggle-lable">
                                            {lang.LABLES.RepeatedFinding}
                                        </span>
                                        <div className="toggle-btn-container-1">
                                            <div
                                                className="toggle-btn2"
                                                id="btn-2"
                                                onClick={() =>
                                                    this.toggleBtnRepeatedFinding('btn-2', index)
                                                }>
                                                <div className="inner-circle"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* <div className="toggle-block-2">
                <span className="toggle-lable">Not Inspected</span>
                <div className="toggle-btn-container-2">
                  <div className="toggle-btn" id="btn-2" onClick={() => this.toggleBtn("btn-2", index)}>
                    <div className="inner-circle"></div>
                  </div>
                </div>
              </div> */}
                            {/* </div> */}
                        </div>
                        <div className="table-outer tabcontent active popup-outer">
                            <div className="question-form-container">
                                <div className="form-group-question">
                                    {/*  selectedType == 1 Line equipment */}
                                    {selectedType === 1 ? (
                                        <>
                                            {isApplicable ? (
                                                <>
                                                    <b> {quesData.long_ques} </b>
                                                </>
                                            ) : (
                                                // <AuditWriteBack
                                                //   applicableData={applicableData}
                                                //   setApplicableData={this.setApplicableData}
                                                //   quesData={quesData}
                                                //   lang={lang}
                                                //   taskInfo={taskInfo}
                                                //   isNotAccessed={this.state.isNotAccessed}
                                                //   onClose={this.props.onClose}
                                                //   taskType={selectedType}
                                                // />
                                                <>
                                                    <label>{quesData.long_ques}</label>
                                                    <input
                                                        type="text"
                                                        className="form-control quantifiable-block"
                                                        id={index}
                                                        value={
                                                            quesData.validation
                                                                ? quesData.validation
                                                                : ''
                                                        }
                                                        disabled={
                                                            !!quesData.isApplicable ||
                                                            task_status == 3
                                                        }
                                                        name="validation"
                                                        onChange={(event) =>
                                                            this.props.restrictNumber(
                                                                event,
                                                                quesData
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) : // selectedType == 2 PM Quality Assessment
                                    selectedType === 2 ? (
                                        <>
                                            {isApplicable ? (
                                                <>
                                                    <b>{quesData.long_ques}</b>
                                                </>
                                            ) : (
                                                // <AuditWriteBack
                                                //   applicableData={applicableData}
                                                //   setApplicableData={this.setApplicableData}
                                                //   quesData={quesData}
                                                //   lang={lang}
                                                //   taskInfo={taskInfo}
                                                //   isNotAccessed={this.state.isNotAccessed}
                                                //   onClose={this.props.onClose}
                                                //   taskType={selectedType}
                                                // />
                                                <>
                                                    <select
                                                        className="form-control"
                                                        id={index}
                                                        name="validation"
                                                        value={
                                                            quesData.validation
                                                                ? quesData.validation
                                                                : ''
                                                        }
                                                        onChange={this.props.handleChange}
                                                        disabled={
                                                            isDisabled || !!quesData.isApplicable
                                                        }>
                                                        <option selected hidden value="">
                                                            {lang.LABLES.SelectOption}
                                                        </option>
                                                        {PMQualityOptions.map(
                                                            (quesData, indexKey) => (
                                                                <option
                                                                    key={indexKey}
                                                                    value={quesData.value}>
                                                                    {quesData.label}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                </>
                                            )}
                                        </>
                                    ) : //selected type = 4   DV Questionnaire
                                    selectedType === 4 ? (
                                        <>
                                            {isApplicable ? (
                                                <>
                                                    <b>{quesData.long_ques}</b>
                                                </>
                                            ) : (
                                                // <AuditWriteBack
                                                //   applicableData={applicableData}
                                                //   setApplicableData={this.setApplicableData}
                                                //   quesData={quesData}
                                                //   lang={lang}
                                                //   taskInfo={taskInfo}
                                                //   isNotAccessed={this.state.isNotAccessed}
                                                //   onClose={this.props.onClose}
                                                //   taskType={selectedType}
                                                // />
                                                <>
                                                    <select
                                                        className="form-control"
                                                        id={index}
                                                        name="validation"
                                                        value={
                                                            quesData.validation
                                                                ? quesData.validation
                                                                : ''
                                                        }
                                                        onChange={this.props.handleChange}
                                                        disabled={
                                                            isDisabled || !!quesData.isApplicable
                                                        }>
                                                        <option selected hidden value="">
                                                            {lang.LABLES.SelectOption}
                                                        </option>
                                                        {DVOptions.map((quesData, indexKey) => (
                                                            <option
                                                                key={indexKey}
                                                                value={quesData.value}>
                                                                {quesData.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </>
                                            )}
                                        </>
                                    ) : // task_status == 2 Reviewer and in Progress
                                    task_status === 2 || task_status === 1 ? (
                                        <React.Fragment>
                                            {isApplicable ? (
                                                <>
                                                    <b> {quesData?.long_ques} </b>
                                                </>
                                            ) : (
                                                // <AuditWriteBack
                                                //   applicableData={applicableData}
                                                //   setApplicableData={this.setApplicableData}
                                                //   quesData={quesData}
                                                //   lang={lang}
                                                //   taskInfo={taskInfo}
                                                //   isNotAccessed={this.state.isNotAccessed}
                                                //   onClose={this.props.onClose}
                                                //   taskType={task_status}
                                                // />
                                                <>
                                                    <b> {quesData?.long_ques} </b>
                                                    <select
                                                        className="form-control"
                                                        id={index}
                                                        name="validation"
                                                        value={
                                                            quesData.validation
                                                                ? quesData.validation
                                                                : ''
                                                        }
                                                        onChange={this.props.handleChange}
                                                        disabled={
                                                            isDisabled || !!quesData.isApplicable
                                                        }>
                                                        <option selected hidden value="">
                                                            {lang.LABLES.SelectOption}
                                                        </option>
                                                        <option value="Pass">
                                                            {lang.LABLES.Pass}
                                                        </option>
                                                        <option value="Minor NC">
                                                            {lang.LABLES.MinorNC}
                                                        </option>
                                                        <option value="Major NC">
                                                            {lang.LABLES.MajorNC}
                                                        </option>
                                                    </select>
                                                </>
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>

                        {(!isApplicable || !!isApplicable) && (
                            <div className="crt-odi-btn-otr">
                                <button
                                    className="btn-prmry"
                                    type="button"
                                    onClick={() => this.onSave(quesData.validation)}>
                                    {lang.BUTTONS.OK}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        );
    }
}

export default QuantifiableModal;
